<template>
  <Loader v-if="groupLoading" />
  <form v-else @submit.prevent="onSubmit">
    <div v-if="error" class="py-3 px-5 mb-3 rounded bg-red-50 text-red-400">
      <span>* {{ error }}</span>
    </div>

    <div class="mb-4 px-1">
      <input
        type="text"
        :placeholder="$t('form-name')"
        class="rounded w-full"
        v-model="name"
      />
    </div>

    <div
      class="flex flex-wrap sm:flex-nowrap justify-between mb-4 space-y-3 sm:space-y-0 sm:space-x-3 px-1"
    >
      <!-- <select-input
        :data="formTypeData"
        :placeholder="$t('form-type')"
        v-model="type"
        size="full"
      /> -->

      <select-input
        :data="getGroupData('name')"
        :placeholder="$t('group')"
        v-model="group"
        size="full"
        :filterMode="true"
        :returnId="true"
        :isObject="true"
        ref="group"
      />
    </div>

    <!-- <div class="justify-between mb-4 space-y-3 sm:space-y-0 sm:space-x-3 px-1"> -->
    <div class="mb-4 px-1">
      <select-input
        :placeholder="subgroupLoading ? 'Loading' : $t('sub-group')"
        :data="getSubGroupData('name')"
        v-model="subgroup"
        size="full"
        :disabled="subgroupLoading"
        :filterMode="true"
        :returnId="true"
        :isObject="true"
        ref="subgroup"
      />
    </div>

    <div class="mb-4 px-1">
      <select-input
        :placeholder="sub_subgroupLoading ? 'Loading' : $t('sub-sub-group')"
        :data="getSubSubGroupData('name')"
        v-model="sub_subgroup"
        :disabled="sub_subgroupLoading"
        size="full"
        :filterMode="true"
        :returnId="true"
        :isObject="true"
        ref="subsubgroup"
      />
    </div>
    <!-- </div> -->

    <div>
      <draggable
        :list="formFields"
        @start="drag = true"
        @end="drag = false"
        handle=".handle"
        ghost-class="ghost"
        :item-key="itemName"
      >
        <transition-group>
          <div
            class="f-field p-1 mb-2 rounded"
            :class="{ 'not-draggable': !enabled }"
            v-for="(field, idx) in formFields || formFields"
            :key="idx"
          >
            <div class="flex justify-between items-center my-2">
              <p class="text-gray-500 text-sm">
                {{ $t("field") }} {{ idx + 1 }}
              </p>
              <div class="flex items-center gap-4 mb-2">
                <button class="handle" type="button">
                  <img
                    class="object-contain w-5"
                    src="/icon-arrange.svg"
                    alt="arrange"
                  />
                </button>
                <button type="button" @click.prevent="removeField(field._id)">
                  <img class="object-contain" src="/trash.svg" alt="delete" />
                </button>
              </div>
            </div>

            <div class="mb-2">
              <input
                class="rounded w-full"
                :placeholder="$t('field-name')"
                type="text"
                v-model="formFields[idx].name"
              />
              <span
                class="text-red-300 text-sm"
                v-if="
                  formFields[idx].type === 'File' &&
                  formFields[idx].name.includes(' ')
                "
                >{{ $t("invalid-name") }}</span
              >
            </div>

            <div class="mb-2">
              <select-input
                :placeholder="$t('field-type')"
                :data="inputDataType"
                v-model="formFields[idx].type"
                size="full"
              />
            </div>

            <div class="mb-2" v-if="formFields[idx].type !== 'Comment'">
              <input
                class="rounded w-full"
                :placeholder="$t('field-placeholder')"
                type="text"
                v-model="formFields[idx].placeholder"
              />
            </div>

            <div
              class="mb-2"
              v-if="
                formFields[idx].type.toLowerCase() === 'radio' ||
                formFields[idx].type.toLowerCase() === 'checkbox' ||
                formFields[idx].type.toLowerCase() === 'select'
              "
            >
              <textarea
                name="options"
                cols="30"
                rows="2"
                v-model="formFields[idx].options"
                :placeholder="`${formFields[idx].type} Question 1`"
                class="rounded w-full"
              ></textarea>
            </div>

            <div class="mb-2">
              <textarea
                name="comment"
                cols="30"
                rows="2"
                v-model="formFields[idx].comment"
                :placeholder="$t('field-comment')"
                class="rounded w-full"
              ></textarea>
            </div>
          </div>
        </transition-group>
      </draggable>
    </div>

    <button type="button" @click="addField" class="text-yellow-600 text-sm">
      {{ $t("add-field") }}
    </button>
    <div class="flex relative items-center justify-end pt-2 gap-x-3 px-1 pb-3">
      <button
        :disabled="loading || deleting || invalidFileName"
        class="rounded text-white py-1.5 px-4 bg-WAORANGE-500 text-sm"
      >
        {{
          form
            ? loading
              ? `${$t("editing")} ${form.name}`
              : `Edit ${form.name}`
            : loading
            ? $t("adding")
            : $t("add-form")
        }}
      </button>
    </div>
  </form>
</template>

<script>
import httpClient from "../../services/httpClient";
import { uuid } from "vue-uuid";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { VueDraggableNext } from "vue-draggable-next";

import SelectInput from "@/components/forms/SelectInput.vue";
import Loader from "@/components/Loader.vue";

export default {
  computed: {
    ...mapGetters("groups", { groupList: "list" }),
    ...mapGetters("groups", { groupLoading: "loading" }),
    ...mapGetters("subgroups", { subgroupList: "list" }),
    ...mapGetters("subgroups", { subgroupLoading: "loading" }),
    ...mapGetters("sub_subgroups", { sub_subgroupList: "list" }),
    ...mapGetters("sub_subgroups", { sub_subgroupLoading: "loading" }),

    invalidFileName() {
      return this.formFields.some(
        (field) => field.type === "File" && field.name.toString().includes(" ")
      );
    },
  },

  components: {
    SelectInput,
    Loader,
    draggable: VueDraggableNext,
  },

  async created() {
    await this.getGroups();
    this.clearSubGroup();
    this.clearSubSubGroup();

    if (this.form) {
      if (this.form.group) await this.getSubGroups(this.form.group?._id);

      if (this.form.subgroup)
        await this.getSubSubGroups(this.form.subgroup?._id);
    }
  },

  data() {
    return {
      itemName: "handle",
      loading: false,
      error: null,
      selectedGroup: this.form?.group || null,
      selectedSubGroup: this.form?.subgroup || null,
      name: this.form ? this.form.name : "",
      type: this.form?.type || "",
      formTypeData: [
        { id: 1, value: "Multiple" },
        { id: 2, value: "Single" },
      ],
      drag: false,
      enabled: true,
      group: {
        id: this.form?.group?._id || "",
        name: this.form?.group?.name || "",
      },

      subgroup: {
        id: this.form?.subgroup?._id || "",
        name: this.form?.subgroup?.name || "",
      },

      sub_subgroup: {
        id: this.form?.sub_subgroup?._id || "",
        name: this.form?.sub_subgroup?.name || "",
      },

      formFields: this.form
        ? JSON.parse(JSON.stringify(this.form?.formFields))
        : [
            {
              uuid: uuid.v4(),
              name: "",
              placeholder: "",
              type: "",
              comment: "",
              options: "",
            },
          ],

      inputDataType: [
        { id: 1, value: "Text" },
        { id: 2, value: "Number" },
        { id: 3, value: "Email" },
        { id: 4, value: "Password" },
        { id: 5, value: "Textarea" },
        { id: 6, value: "Radio" },
        { id: 7, value: "Checkbox" },
        { id: 8, value: "Select" },
        { id: 9, value: "Date" },
        { id: 10, value: "File" },
        { id: 11, value: "Comment" },
      ],
    };
  },

  emits: ["onClose"],

  methods: {
    ...mapMutations("forms", ["addForm", "updateForm"]),
    ...mapMutations("subgroups", ["clearSubGroup"]),
    ...mapMutations("sub_subgroups", ["clearSubSubGroup"]),
    ...mapActions("groups", ["getGroups"]),
    ...mapActions("subgroups", ["getSubGroups"]),
    ...mapActions("sub_subgroups", ["getSubSubGroups"]),

    async onSubmit() {
      if (this.error) this.error = null;
      if (!this.group?.id && this.subgroup?.id)
        return (this.error = "Please choose group and sub group");
      if (!this.formFields.length)
        return (this.error = "Please add atleast 1 field to form");

      try {
        this.loading = true;
        if (this.form) {
          const { data } = await httpClient.put(
            `/admin/forms/${this.form._id}`,
            {
              name: this.name,
              // type: this.type, //hidden for now
              group: this.group.id,
              subgroup: this.subgroup.id,
              sub_subgroup: this.sub_subgroup.id,
              formFields: this.formFields.map((field) => ({
                name: field.name,
                placeholder: field.placeholder,
                type: field.type,
                comment: field.comment,
                options: field.options,
              })),
            }
          );

          this.updateForm(data);
        } else {
          const { data } = await httpClient.post(`/admin/forms`, {
            name: this.name,
            // type: this.type, //hidden for now
            group: this.group.id,
            subgroup: this.subgroup.id,
            sub_subgroup: this.sub_subgroup.id,
            formFields: this.formFields.map((field) => ({
              name: field.name,
              placeholder: field.placeholder,
              type: field.type,
              comment: field.comment,
              options: field.options,
            })),
          });

          this.addForm(data);
        }

        this.loading = false;
        this.$emit("onClose");
      } catch (err) {
        this.loading = false;
        this.error =
          err.response && err.response.data.error
            ? err.response.data.error
            : err.error || err.message;
      }
    },

    addField() {
      this.formFields = [
        ...this.formFields,
        {
          _id: uuid.v4(),
          name: "",
          placeholder: "",
          type: "",
          options: "",
        },
      ];
    },

    removeField(id) {
      this.formFields = this.formFields.filter((field) => field._id !== id);
    },

    getGroupData(name) {
      return this.groupList.map((group) => ({
        id: group._id,
        value: group[name],
      }));
    },

    getSubGroupData(name) {
      return this.subgroupList.map((subg) => ({
        id: subg._id,
        value: subg[name],
      }));
    },

    getSubSubGroupData(name) {
      return this.sub_subgroupList.map((group) => ({
        id: group._id,
        value: group[name],
      }));
    },
  },
  props: ["form"],

  watch: {
    async group(newValue) {
      this.clearSubGroup();
      this.clearSubSubGroup();
      this.$refs.subgroup.resetData();
      this.$refs.subsubgroup.resetData();

      const found = this.groupList.find((group) => group._id === newValue.id);

      if (found) {
        this.selectedGroup = found;
        await this.getSubGroups(newValue.id);

        this.clearSubSubGroup();
      } else {
        this.selectedGroup = null;
      }
    },

    async subgroup(newValue) {
      this.$refs.subsubgroup.resetData();

      const found = this.subgroupList.find(
        (subgroup) => subgroup._id === newValue.id
      );

      if (found) {
        this.selectedSubGroup = found;

        await this.getSubSubGroups(newValue.id);
      } else this.selectedSubGroup = null;
    },
  },
};
</script>

<style scoped>
input,
textarea {
  border: none;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  font-size: 14px;
}

form {
  width: 80vw;
  max-height: 100vh;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.not-draggable {
  cursor: no-drop;
}

@media screen and (min-width: 640px) {
  form {
    width: 500px;
  }
}
</style>
